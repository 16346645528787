/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin form {
  /*padding: 0 5px 0 5px;*/
  padding: 10px 10px 10px 10px;
  font-size: 14px;
}
.altai-theme-admin .altai-form-no-padding {
  padding: 0;
}
.altai-theme-admin .altai-form .altai-simple-form {
  background-color: #f3f3f3;
  padding: 5px;
  border-top: 4px solid #eee;
}
.altai-theme-admin .altai-form .form-group,
.altai-theme-admin .altai-form .form-buttons,
.altai-theme-admin .altai-form h2 {
  margin: 10px 5px 5px 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.altai-theme-admin .altai-form .form-buttons.mainbuttonrow {
  margin-top: 10px;
}
.altai-theme-admin .altai-form h2 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 10px 5px 10px 5px;
}
.altai-theme-admin .altai-form .form-group .add-item,
.altai-theme-admin .altai-form .form-group .remove-item {
  color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.altai-theme-admin .altai-form .form-group altai-content-item-update-inner {
  width: 100%;
}
.altai-theme-admin .altai-form .form-group .add-item {
  background-color: #0068ff;
}
.altai-theme-admin .altai-form .form-group .remove-item {
  background-color: red;
}
.altai-theme-admin .altai-form .form-group label {
  display: block;
  /*padding-top: 7px;*/
  padding-top: 0;
  color: #525252;
  font-size: 12px;
  flex: 0 0 125px;
}
.altai-theme-admin .altai-form .form-group input[type=text],
.altai-theme-admin .altai-form .form-group input[type=number],
.altai-theme-admin .altai-form .form-group .altai-date-input,
.altai-theme-admin .altai-form .form-group select {
  padding: 7px;
  /*max-width: 500px;*/
  width: 100%;
  background-color: #fEFEFE;
  border: 1px solid #e3e3e3;
  border-left: 4px solid #e3e3e3;
}
.altai-theme-admin .altai-form .form-group input[disabled] {
  background-color: #F5f5f5;
}
.altai-theme-admin .altai-form .form-group .altai-content-attribute-update-item.Text {
  max-width: 100%;
}
.altai-theme-admin .altai-form .form-group .altai-content-attribute-update-item {
  margin-bottom: 10px;
  /*max-width: 600px;*/
  width: 100%;
  display: flex;
  margin-left: 10px;
}
.altai-theme-admin .altai-form .form-group .altai-content-attribute-update-item .altai-content-attribute-update-lang {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 15px;
  background-color: #e4e4e4;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
}
.altai-theme-admin .altai-form .form-group .altai-content-attribute-update-item .altai-content-attribute-update-tpl {
  flex: 1;
  display: flex;
}
.altai-theme-admin .altai-form .altai-content-relation-update-item-wrapper {
  background-color: #F9F9F9;
}
.altai-theme-admin .altai-form .altai-content-relation-update-item-wrapper .form-buttons {
  margin: 0;
  margin-bottom: 10px;
}
.altai-theme-admin .altai-form .altai-content-relation-update-item-wrapper .remove-item {
  margin-top: 10px;
}
.altai-theme-admin .altai-form .form-buttons {
  display: flex;
  /*button, button.toggle-button.active {
                background-color: transparent;
                padding: 2px 10px;
                color: @highlight-color;
                border: 2px solid @highlight-color;
                text-transform: uppercase;
                margin-right: 5px;
        }

        button.toggle-button {
            color: @highlight-color;
            background-color: white;
            border: 3px solid @highlight-color;
        }

        .right-button {
            flex: 1;
            text-align: right;
        }*/
}
